import { logoutSuccess } from '@core/auth/store/auth.actions';
import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import { Profile } from 'src/domain/profile/profile';
import {
  getProfile,
  getProfileError,
  getProfileSuccess,
  resetState,
  updateAddress,
  updateAddressError,
  updateAddressSuccess,
  updatePersonalDetails,
  updatePersonalDetailsError,
  updatePersonalDetailsSuccess
} from './profile.actions';

export const featureKeyProfile = 'profile';

export interface FeatureStateProfile {
  profile: Loadable<Profile, ApiError>;
  shopifyId?: string;
  shippingAddressUpdated: Loadable<boolean, ApiError>;
}

export interface AppState {
  [featureKeyProfile]: FeatureStateProfile;
}

export const featureStateProfile: FeatureStateProfile = {
  profile: { isLoading: false },
  shopifyId: '-1',
  shippingAddressUpdated: { isLoading: false }
};

export const reducerProfile = createReducer(
  featureStateProfile,
  on(getProfile, (state) => ({
    ...state,
    profile: LoadableStateReducerHelper.startLoad(state.profile)
  })),
  on(getProfileSuccess, (state, { profile }) => ({
    ...state,
    profile: LoadableStateReducerHelper.loadSuccess(profile),
    shopifyId: profile.shopifyId
  })),
  on(getProfileError, (state, { error }) => ({
    ...state,
    profile: LoadableStateReducerHelper.loadError(state.profile, error)
  })),
  on(updatePersonalDetails, (state) => ({
    ...state,
    profile: LoadableStateReducerHelper.startLoad(state.profile)
  })),
  on(updatePersonalDetailsSuccess, (state, { profile }) => ({
    ...state,
    profile: LoadableStateReducerHelper.loadSuccess(profile)
  })),
  on(updatePersonalDetailsError, (state, { error }) => ({
    ...state,
    profile: LoadableStateReducerHelper.loadError(state.profile, error)
  })),
  on(updateAddress, (state) => ({
    ...state,
    profile: LoadableStateReducerHelper.startLoad(state.profile),
    shippingAddressUpdated: LoadableStateReducerHelper.startLoad(state.shippingAddressUpdated)
  })),
  on(updateAddressSuccess, (state, { profile }) => ({
    ...state,
    profile: LoadableStateReducerHelper.loadSuccess(profile),
    shippingAddressUpdated: LoadableStateReducerHelper.loadSuccess(true)
  })),
  on(updateAddressError, (state, { error }) => ({
    ...state,
    profile: LoadableStateReducerHelper.loadError(state.profile, error),
    shippingAddressUpdated: LoadableStateReducerHelper.loadError(state.shippingAddressUpdated, error)
  })),
  on(logoutSuccess, (): FeatureStateProfile => featureStateProfile),
  on(resetState, (): FeatureStateProfile => featureStateProfile)
);
