<div class="layout-bg hero-bg">
  <div class="position-relative" *ngrxLet="breakpointService.downMd$ as downMd">
    <div
      class="hero-wave-background position-absolute top-0 h-100 w-100"
      [style.background-image]="'url(' + vm?.heroImage?.responsiveImage?.src + ')'"
      [style.background-position]="vm?.heroImage?.focalPoint?.x + '% ' + vm?.heroImage?.focalPoint?.y + '%'"
    ></div>
    <div class="background-container">
      <svg
        viewBox="0 0 1440 1764"
        [attr.height]="downMd ? '1225' : '100%'"
        [attr.width]="downMd ? '1000' : '100%'"
        [attr.preserveAspectRatio]="downMd ? 'xMidYMin slice' : 'none'"
      >
        <defs>
          <mask id="a">
            <rect width="100%" height="100%" fill="white" />
            <path
              fill="black"
              d="M634.384 596.947 975 289.127 634.518-18.83c-163.38-147.772-414.427-145.23-574.719 5.82-174.449 164.39-174.391 439.823.127 604.141 160.256 150.89 411.117 153.43 574.458 5.816Z"
            />
            <path fill="black" d="M1452 363.938h-436.2A321.822 321.822 0 0 0 793.556 453H-7V33h1459v330.938Z" />
          </mask>
        </defs>
        <path
          mask="url(#a)"
          [attr.fill]="vm?.backgroundColor ?? '#fff1e3'"
          d="M277.756 1248.44-480 563.783l757.459-684.964c363.465-328.678 921.961-323.024 1278.561 12.943 388.09 365.641 387.96 978.264-.29 1343.748-356.51 335.61-914.595 341.26-1277.974 12.93z"
        />
      </svg>
    </div>
  </div>
</div>

<section class="hero d-flex flex-column gap-2">
  <div class="d-flex flex-column flex-md-row-reverse gap-2">
    <div class="d-flex align-items-center justify-content-center col-md-6">
      <ng-container *appSkeleton="!vm; repeat: 1; className: ['rounded-3', 'mt-2']; height: '400px'">
        <div
          *ngIf="vm && vm.cta"
          [style.background-color]="vm.heroBoxBackgroundColor"
          class="d-flex flex-column justify-content-center align-items-center align-items-md-start text-center text-md-start flex-grow-1 p-5 shadow-normal rounded-3"
        >
          <h1 class="hero-title mb-2 header-h5 header-h3-lg">{{ vm.title }}</h1>
          <div class="mb-3 color-text400 body-normal body-large-lg" [innerHTML]="vm.content"></div>
          <div class="d-flex flex-column flex-md-row gap-2">
            <a
              *ngFor="let cta of vm.cta; trackBy: trackByFn"
              ddButton
              class="btn btn-large btn-primary btn-text"
              [class.background-bg100]="cta.level === 'tertiary' || cta.level === 'secondary'"
              [level]="cta.level"
              [routerLink]="[RouteSegment.Root, cta.route] | localizeUrl"
            >
              {{ cta.ctaTitle }}
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *appSkeleton="!vm; className: ['rounded-3', 'mt-1']; height: '200px'">
    <div *ngIf="vm && vm.introductionBlock" class="d-flex justify-content-center mt-2 mt-md-10">
      <div class="offset-border offset-border-sm rounded-2 rounded-sm-3 background-primary300 py-5 ps-5 pe-7">
        <div class="d-flex flex-column flex-md-row gap-3 align-items-center">
          <svg-icon class="flex-shrink-0 intro-icon" key="service-4" fontSize="112px"></svg-icon>
          <div>
            <div class="body-normal-bold header-h6-bold-md mb-1">{{ vm.introductionBlock.title }}</div>
            <div class="body-normal-regular mb-0 color-text400" [innerHTML]="vm.introductionBlock.content | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>
