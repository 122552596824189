import { gql } from '@apollo/client';
import { MediaBlockFragment } from '@core/services/dato/fragments/media-block.fragment';
import { ExtraDetailsBlockFragment } from '@core/services/dato/fragments/extra-details-block.fragment';
import { FaqFragment } from '@core/services/dato/fragments/faq.fragment';

export const ProductFragment = gql`
  ${MediaBlockFragment}
  ${ExtraDetailsBlockFragment}
  ${FaqFragment}
  fragment Product on ProductRecord {
    id
    sku
    slug
    type: __typename
    subtitle
    title
    position
    published
    thirdPartyProducts {
      thirdPartySku
    }
    whatDoWeTestTabTitle {
      tabTitle
    }
    whatSInTheBoxTitle {
      tabTitle
    }
    locale @client
    boxContent {
      text
    }
    testkitIcon {
      url
      alt
    }
    secondaryTestkitIcon {
      url
      alt
    }
    faq {
      ...Faq
    }
    biomarkers {
      id
      biomarkerId
      biomarkerIcon {
        url
        alt
      }
      secondaryBiomarkerIcon {
        url
        alt
      }
      description
      name
      resultText
      articleLabels: labels {
        id
      }
      resultEvaluations {
        resultLevel
        explanation
        referral
      }
    }
    biomarkersIntro
    description
    mediaBlockOne {
      ...MediaBlock
    }
    mediaBlockTwo {
      ...MediaBlock
    }
    extraDetailsBlock {
      ...ExtraDetailsBlock
    }
    unboxingVideo {
      id
      title
      videoSubtitles {
        subtitleLocale
        videoSubtitleFile {
          url
        }
      }
      videoPreviewImage {
        id
        title
        format
        url
      }
      videoFile: video {
        title
        url
        format: mimeType
        videoThumbnail: video {
          url: thumbnailUrl
        }
      }
    }
    recommendedFor {
      text
    }
    crossSellingProducts {
      id
      locale @client
      sku
      slug
      subtitle
      published
      title
      boxContent {
        text
      }
      testkitIcon {
        url
        alt
      }
      secondaryTestkitIcon {
        url
        alt
      }
      faq {
        ...Faq
      }
      biomarkers {
        id
        biomarkerIcon {
          url
          alt
        }
        secondaryBiomarkerIcon {
          url
          alt
        }
        description
        name
        articleLabels: labels {
          id
        }
      }
      biomarkersIntro
      description
      mediaBlockOne {
        ...MediaBlock
      }
      mediaBlockTwo {
        ...MediaBlock
      }
      unboxingVideo {
        video {
          url
          format
        }
        videoPreviewImage {
          url
        }
      }
      recommendedFor {
        text
      }
    }
  }
`;

export const ProductWithImagesFragment = gql`
  ${MediaBlockFragment}
  ${ExtraDetailsBlockFragment}
  ${FaqFragment}
  ${ProductFragment}
  fragment ProductWithImage on ProductRecord {
    ...Product
    images: productImages {
      url
      alt
      responsiveImage(imgixParams: { fit: crop, w: 1200, crop: focalpoint, fm: webp }) {
        src
        srcSet
        sizes
        webpSrcSet
      }
    }
  }
`;
