import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { debounceTime, map, Observable } from 'rxjs';
import { loginWithMagicToken } from '@core/auth/store/auth.actions';
import { selectProfileVM } from '@shared/store/profile/profile.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const PROFILE_DEBOUNCE_TIME = 300;

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BarcodeActivationGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = route.queryParamMap.get('token');
    if (token) {
      this.store.dispatch(loginWithMagicToken({ token, shouldNavigate: false }));
    }
    return this.getGuardResult$();
  }

  private getGuardResult$(): Observable<boolean | UrlTree> {
    const profile$ = this.store.select(selectProfileVM);

    return profile$.pipe(
      debounceTime(PROFILE_DEBOUNCE_TIME),
      untilDestroyed(this),
      map((profile) => {
        if (!!profile.personalDetails) {
          return true;
        }
        return this.router.createUrlTree([RouteSegment.Root, RouteSegment.BarcodeActivation]);
      })
    );
  }
}
