import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfileVM } from 'src/app/dashboard/pages/profile/profile.vm';
import { featureKeyProfile, FeatureStateProfile } from './profile.reducer';

const selectProfileFeature = createFeatureSelector<FeatureStateProfile>(featureKeyProfile);

export const selectProfile = createSelector(selectProfileFeature, (state) => state.profile);

export const selectProfileVM = createSelector(selectProfileFeature, (state): ProfileVM => {
  return {
    ...state.profile.data!,
    isLoading: state.profile.isLoading
  };
});

export const selectShippingAddressUpdated = createSelector(selectProfileFeature, (state) => state.shippingAddressUpdated);
