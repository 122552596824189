import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MswLoaderService {
  public async load(apiBasePath: string): Promise<ServiceWorkerRegistration | void> {
    const { mockWorker } = await import('@shared/msw');
    return await mockWorker(apiBasePath)
      .start({ serviceWorker: { url: '/mockServiceWorker.js' }, onUnhandledRequest: 'bypass' })
      .catch((error: Error) => console.error(error));
  }
}
