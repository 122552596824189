import { Injectable } from '@angular/core';
import { DatoService } from '@core/services/dato/dato.service';
import { gql } from '@apollo/client';
import { map, Observable } from 'rxjs';
import { ProductFragment } from '@core/services/dato/fragments/product.fragment';
import { DatoProductItem } from '@core/services/dato/interfaces/product.interface';
import { PageService } from '@shared/services/page.service';
import { ProductHandle } from '@core/services/dato/interfaces/product-handle.interface';
import { ProductFilterResult } from '@shop/pages/available-tests/pages/product/components/product-filter/product-filter.interface';
import { DatoProductFilter } from '../interfaces/product-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class DatoProductDataService {
  constructor(
    private readonly datoService: DatoService,
    private readonly pageService: PageService
  ) {}

  public getProduct$(
    locale: string = 'en',
    handle: ProductHandle,
    includeMetaData = false,
    imageWidth = 1000,
    disableCache = false
  ): Observable<DatoProductItem> {
    return this.datoService
      .get$<{ product: DatoProductItem }>(
        gql`
          ${ProductFragment}
          query getProduct {
              product(locale: ${locale}, fallbackLocales: [nl],
                  filter: {
                OR:[
                  {${this.getProductFilter(handle)}},
                  {thirdPartyProducts: {any: {thirdPartyProductBlock: {thirdPartySku: {eq: "${handle.sku}"}}}}}]
                  }
              ) {
                  ...Product,
                hidden
                videoSubtitles {
                  video {
                    url
                    format
                  }
                  subtitles: videoSubtitles {
                    subtitleLocale,
                    videoSubtitleFile {
                      url
                    }
                  }
                }
                images: productImages {
                  url
                  alt
                  responsiveImage(imgixParams: { fit: crop, w: ${imageWidth}, crop: focalpoint, fm: webp }) {
                    src
                    srcSet
                    sizes
                    webpSrcSet
                  }
                  format,
                  videoThumbnail: video {
                    thumbnailUrl
                  }
                }
                  meta: _seoMetaTags {
                      content
                      attributes
                      tag
                  }
              }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          if (includeMetaData) {
            this.pageService.setMeta(res.product.meta);
          }
          return res.product;
        })
      );
  }

  public getProducts$(
    locale: string = 'en',
    skus: string[],
    published = true,
    imageWidth = 1200,
    disableCache = false,
    limit: number = 100,
    filters?: ProductFilterResult
  ): Observable<DatoProductItem[]> {
    let biomarkerFilters: string[] = [];
    let productFilters: string[] = [];
    if (filters) {
      const { biomarkers, ...rest } = filters;
      biomarkerFilters = biomarkers ?? [];
      productFilters = Object.values(rest).flat().flat();
    }
    return this.datoService
      .get$<{ allProducts: DatoProductItem[] }>(
        gql`
          ${ProductFragment}
          query getAllProducts {
            allProducts(locale: ${locale},
              fallbackLocales: [nl],
              first: ${limit},
              filter: {
              ${biomarkerFilters?.length ? `biomarkers: { allIn: ["${biomarkerFilters.join('", "')}"] }` : ''},
              ${productFilters?.length ? `filters: {allIn: ["${productFilters.join('", "')}"]}` : ''},
              ${published ? 'published: { eq: true }' : ''},
              hidden: { eq: false },
                OR: [
                  { sku: { in: ["${skus.join('", "')}"] }},
                  { thirdPartyProducts: {
                    any: { thirdPartyProductBlock:
                         { thirdPartySku: { in: ["${skus.join('", "')}"] }}
                    }
                  },
                  }
                ],
              },
              orderBy: position_ASC) {
              ...Product,
              images: productImages {
                url
                alt
                responsiveImage(imgixParams: { fit: crop, w: ${imageWidth}, crop: focalpoint, fm: webp }) {
                  src
                  srcSet
                  sizes
                  webpSrcSet
                }
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return res.allProducts;
        })
      );
  }

  public getProductFilters$(locale: string = 'nl', disableCache = false): Observable<DatoProductFilter> {
    return this.datoService
      .get$<DatoProductFilter>(
        gql`
          query FilterMenu {
            productFilters: productFilterMenu(locale: ${locale}, fallbackLocales: [nl]) {
              id
              title
              group {
                title
                id
                biomarkerFilter
                filterType {
                  id
                  title
                  queryParam
                  referencingProductFilters: _allReferencingProductFilters {
                    id
                    title
                    queryParam
                    referencingProductsMeta:_allReferencingProductsMeta(
                      filter: {published: {eq: true}, hidden: {eq: false}}
                    ){
                      count
                    }
                  }
                }
              }
            }
            biomarkerFilters: allBiomarkers(first: 100) {
              biomarkerId
              description
              id
              name
              queryParam
              referencingProductsMeta: _allReferencingProductsMeta(
                filter: {published: {eq: true}, hidden: {eq: false}}
              ){
                count
              }
            }
          }
        `,
        disableCache,
        { locale }
      )
      .pipe(
        map((res) => {
          return {
            ...res,
            productFilters: {
              ...res.productFilters,
              group: res.productFilters.group.filter((group) => group.filterType!.referencingProductFilters.length > 0)
            },
            biomarkerFilters: res.biomarkerFilters.filter((biomarker) => biomarker.referencingProductsMeta.count > 0)
          } as DatoProductFilter;
        })
      );
  }

  private getProductFilter(handle: ProductHandle): string {
    return handle.slug ? `slug: { eq: "${handle.slug}" }` : `sku: { eq: "${handle.sku}" }`;
  }
}
